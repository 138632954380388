import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestOrders } from '../../Redux/Orders/OrdersActions';
import OrdersDataTable from './DataTable/ordersDataTable';
import Loader from '../../Components/Loader/Loader';

const OrderList = () => {
  const dispatch = useDispatch();
  const { loading, orders } = useSelector((store) => store.ordersReducer);
  const [_orders, setOrders] = useState([]);
  useEffect(() => {
    dispatch(getLatestOrders());
  }, [dispatch]);

  useEffect(() => {
    console.log({ orders: orders });
    if (orders.data) {
      setOrders(orders.data);
    }
  }, [orders]);
  return (
    <div className="w-full p-14">
      <div>
        <h1 className="text-4xl">Order List</h1>
        <div className="bg-black mt-10 p-10 rounded-lg">
          {!loading && <OrdersDataTable dataEntered={_orders} />}
          {loading && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default OrderList;
