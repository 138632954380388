import React from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../../Shared/customStyle';

const ShowOrderModal = ({ isOpen, closeModal, data }) => {
  if (!data) return null;
  console.log(data);

  const formattedDate = new Date(data.date).toLocaleDateString();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Order Details"
      style={customStyles}
    >
      <div className=" text-white rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-6">Order Details</h2>

        {/* Order Information */}
        <div className="mb-6 space-y-2">
          <p>
            <span className="font-semibold">Order ID:</span> {data.orderId}
          </p>
          <p>
            <span className="font-semibold">Person:</span> {data.person}
          </p>
          <p>
            <span className="font-semibold">Email:</span> {data.email}
          </p>
          <p>
            <span className="font-semibold">Status:</span>{' '}
            <span
              className={`px-2 py-1 rounded-md font-medium ${
                data.status === 'Pending'
                  ? 'bg-yellow-400 text-black'
                  : 'bg-green-500 text-black'
              }`}
            >
              {data.status}
            </span>
          </p>
          <p>
            <span className="font-semibold">Location:</span> {data.location}
          </p>
          <p>
            <span className="font-semibold">Quantity:</span> {data.quantity}
          </p>
          <p>
            <span className="font-semibold">Amount:</span> ${data.amount}
          </p>
          <p>
            <span className="font-semibold">Date:</span> {formattedDate}
          </p>
        </div>

        {/* Product Information */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-4">Products:</h3>
          <ul className="space-y-4">
            {data.products.map((product, index) => (
              <li
                key={index}
                className="flex items-center justify-between border-b border-gray-700 pb-4"
              >
                <div className="flex items-center gap-4">
                  <img
                    src={process.env.REACT_APP_IMAGE_BASE_URL + product.image}
                    alt={product.name}
                    className="w-12 h-12 object-cover rounded-md"
                  />
                  <p>{product.name}</p>
                </div>
                <p className="text-sm font-medium">
                  Quantity: {product.quantity}
                </p>
              </li>
            ))}
          </ul>
        </div>

        {/* Close Button */}
        <div className="mt-4 flex justify-end">
          <button
            onClick={closeModal}
            className="bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-lg font-semibold transition duration-300"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ShowOrderModal;
