import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  menPageData: [],

  aAIHomeSectionLoading: false,
  aAIHomeSection: [],
  aAIHomeSectionError: false,

  rIHomeSectionLoading: false,
  rIHomeSection: [],
  rIHomeSectionError: false,

  dIHomeSectionLoading: false,
  dIHomeSection: [],
  dIHomeSectionError: false,

  rIRecommendedSectionLoading: false,
  rIRecommendedSection: [],
  rIRecommendedSectionError: false,

  dIRecommendedSectionLoading: false,
  dIRecommendedSection: [],
  dIRecommendedSectionError: false,

  rISpecialSectionLoading: false,
  rISpecialSection: [],
  rISpecialSectionError: false,

  dISpecialSectionLoading: false,
  dISpecialSection: [],
  dISpecialSectionError: false,

  rISpecialBackgroundSectionLoading: false,
  rISpecialBackgroundData: [],
  rISpecialBackgroundSectionError: false,

  dISpecialBackgroundSectionLoading: false,
  dISpecialBackgroundData: [],
  dISpecialBackgroundSectionError: false,
};

const menPageSlice = createSlice({
  name: 'menPage',
  initialState,
  reducers: {
    getMenPageDataRequest: (state, action) => {
      state.loading = true;
      state.error = false;
      state.menPageData = [];
    },
    getMenPageDataSuccess: (state, action) => {
      state.loading = false;
      state.menPageData = action.payload;
      state.error = false;
    },
    getMenPageDataFailure: (state, action) => {
      state.error = action.payload;
      state.menPageData = false;
      state.packages = [];
    },

    aAIHomeSectionRequest: (state, action) => {
      state.aAIHomeSectionLoading = true;
      state.aAIHomeSection = [];
      state.aAIHomeSectionError = false;
    },
    aAIHomeSectionSuccess: (state, action) => {
      state.aAIHomeSectionLoading = false;
      state.aAIHomeSection = action.payload;
      state.aAIHomeSectionError = false;
    },
    aAIHomeSectionFailure: (state, action) => {
      state.aAIHomeSectionLoading = false;
      state.aAIHomeSectionError = action.payload;
      state.aAIHomeSection = [];
    },

    rIHomeSectionRequest: (state, action) => {
      state.rIHomeSectionLoading = true;
      state.rIHomeSection = [];
      state.rIHomeSectionError = false;
    },
    rIHomeSectionSuccess: (state, action) => {
      state.rIHomeSectionLoading = false;
      state.rIHomeSection = action.payload;
      state.rIHomeSectionError = false;
    },
    rIHomeSectionFailure: (state, action) => {
      state.rIHomeSectionLoading = false;
      state.rIHomeSection = [];
      state.rIHomeSectionError = action.payload;
    },

    dIHomeSectionRequest: (state, action) => {
      state.dIHomeSectionLoading = true;
      state.dIHomeSection = [];
      state.dIHomeSectionError = false;
    },
    dIHomeSectionSuccess: (state, action) => {
      state.dIHomeSectionLoading = false;
      state.dIHomeSection = action.payload;
      state.dIHomeSectionError = false;
    },
    dIHomeSectionFailure: (state, action) => {
      state.dIHomeSectionLoading = false;
      state.dIHomeSection = [];
      state.dIHomeSectionError = action.payload;
    },

    rIRecommendedSectionRequest: (state, action) => {
      state.loading = true;
      state.rIRecommendedSection = [];
      state.error = false;
    },
    rIRecommendedSectionSuccess: (state, action) => {
      state.loading = false;
      state.rIRecommendedSection = action.payload;
      state.error = false;
    },
    rIRecommendedSectionFailure: (state, action) => {
      state.loading = false;
      state.rIRecommendedSection = [];
      state.error = true;
    },

    dIRecommendedSectionRequest: (state, action) => {
      state.loading = true;
      state.dIRecommendedSection = [];
      state.error = false;
    },
    dIRecommendedSectionSuccess: (state, action) => {
      state.loading = false;
      state.dIRecommendedSection = action.payload;
      state.error = false;
    },
    dIRecommendedSectionFailure: (state, action) => {
      state.loading = false;
      state.dIRecommendedSection = [];
      state.error = action.payload;
    },
    rISpecialSectionRequest: (state, action) => {
      state.loading = true;
      state.rISpecialSection = [];
      state.error = false;
    },
    rISpecialSectionSuccess: (state, action) => {
      state.loading = false;
      state.rISpecialSection = action.payload;
      state.error = false;
    },
    rISpecialSectionFailure: (state, action) => {
      state.loading = false;
      state.rISpecialSection = [];
      state.error = true;
    },
    dISpecialSectionRequest: (state, action) => {
      state.loading = true;
      state.dISpecialSection = [];
      state.error = false;
    },
    dISpecialSectionSuccess: (state, action) => {
      state.loading = false;
      state.dISpecialSection = action.payload;
      state.error = false;
    },
    dISpecialSectionFailure: (state, action) => {
      state.loading = false;
      state.dISpecialSection = [];
      state.error = action.payload;
    },

    rISpecialBackgroundSectionRequest: (state, action) => {
      state.rISpecialBackgroundSectionLoading = true;
      state.rISpecialBackgroundData = [];
      state.rISpecialBackgroundSectionError = false;
    },
    rISpecialBackgroundSectionSuccess: (state, action) => {
      state.rISpecialBackgroundSectionLoading = false;
      state.rISpecialBackgroundData = action.payload;
      state.rISpecialBackgroundSectionError = false;
    },
    rISpecialBackgroundSectionFailure: (state, action) => {
      state.rISpecialBackgroundSectionLoading = false;
      state.rISpecialBackgroundData = [];
      state.rISpecialBackgroundSectionError = action.payload;
    },
    dISpecialBackgroundSectionRequest: (state, action) => {
      state.dISpecialBackgroundSectionLoading = true;
      state.dISpecialBackgroundData = [];
      state.dISpecialBackgroundSectionError = false;
    },
    dISpecialBackgroundSectionSuccess: (state, action) => {
      state.dISpecialBackgroundSectionLoading = false;
      state.dISpecialBackgroundData = action.payload;
      state.dISpecialBackgroundSectionError = false;
    },
    dISpecialBackgroundSectionFailure: (state, action) => {
      state.dISpecialBackgroundSectionLoading = false;
      state.dISpecialBackgroundData = [];
      state.dISpecialBackgroundSectionError = action.payload;
    },
  },
});

export const MensPageAction = menPageSlice.actions;
export default menPageSlice;
