import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { editOrderStatus } from '../../../Redux/Orders/OrdersActions';
import ShowOrderModal from '../Modal/ShowOrderModal';
import DeleteOrderModal from '../Modal/DeleteOrderModal';

const generateColumns = (
  data,
  handleStatusChange,
  handleDelete,
  handleShow
) => {
  if (!data || data.length === 0) return [];

  // Add the "Actions" column
  const columns = Object.keys(data[0])
    .map((key) => {
      if (key.includes('orderId')) return null;
      if (key.includes('email')) return null;
      return {
        name: (
          <div className="w-36 text-center">
            <h1>{formatColumnName(key)}</h1>
          </div>
        ),
        selector: (row) => row[key],
        sortable: true,
        cell: (row) => (
          <div className="w-full">
            {key === 'amount' ? (
              <h1 className="text-center">$ {row[key]}</h1>
            ) : key === 'status' ? (
              <div
                className={`p-2 px-5 text-center flex items-center border rounded-lg 
                    ${
                      row[key] === 'Delivered'
                        ? 'bg-green-400'
                        : row[key] === 'Pending'
                        ? 'bg-yellow-400'
                        : 'bg-red-500'
                    }`}
              >
                <select
                  value={row[key]}
                  onChange={(e) =>
                    handleStatusChange(row['orderId'], e.target.value)
                  }
                  className="w-full outline-none bg-transparent"
                >
                  <option value="Delivered">Delivered</option>
                  <option value="Pending">Pending</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </div>
            ) : key === 'image' ? (
              <div className="flex flex-row items-center gap-3">
                <img src={row[key]} alt="" width={100} />
                <h1>{row['name']}</h1>
              </div>
            ) : key === 'date' ? (
              <h1 className="w-full text-center">
                {new Date(row[key]).toDateString()}
              </h1>
            ) : key === 'location' ? (
              <div className="w-full text-center">
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    row[key]
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {row[key]}
                </a>
              </div>
            ) : key === 'products' ? (
              <div className="w-full text-center">
                {row[key].map((product) => product.name).join(', ')}
              </div>
            ) : (
              <h1 className="w-full text-center">{row[key]}</h1>
            )}
          </div>
        ),
      };
    })
    .filter((element) => element != null);

  // Add "Actions" column
  columns.push({
    name: (
      <div className="w-36 text-center">
        <h1>Actions</h1>
      </div>
    ),
    selector: (row) => row.orderId,
    cell: (row) => (
      <div className="flex justify-center gap-2">
        <button
          onClick={() => handleShow(row)}
          className="bg-blue-500 text-white p-2 rounded-lg"
        >
          Show
        </button>
        <button
          onClick={() => handleDelete(row)}
          className="bg-red-500 text-white p-2 rounded-lg"
        >
          Delete
        </button>
      </div>
    ),
  });

  return columns;
};

const formatColumnName = (name) => {
  return name
    .replace(/([A-Z])/g, ` $1`)
    .replace(/^./, (str) => str.toUpperCase());
};

const OrdersDataTable = ({ dataEntered }) => {
  const dispatch = useDispatch();

  const [isShowOrderModalOpen, setIsShowOrderModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [dataSelected, setDataSelected] = useState(null);
  const handleRowClicked = (row) => {
    setDataSelected(row);
    //setIsShowOrderModalOpen(true);
  };

  const openShowModal = (row) => {
    setDataSelected(row);
    setIsShowOrderModalOpen(true);
  };

  const openDeleteModal = (row) => {
    setDataSelected(row);
    setDeleteModalOpen(true);
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: '#323D4E', // Header background color
        color: '#FFFFFF', // Header text color
        fontWeight: 'bold', // Header text weight
        fontSize: '20px',
      },
    },
    rows: {
      style: {
        color: '#FFFFFF', // Default row text color
        backgroundColor: 'black', // Default row background color
        borderBottom: '1px solid white',
      },
    },
    cells: {
      style: {
        padding: '12px', // Cell padding
        fontSize: '20px',
      },
    },
  };

  const desiredOrder = [
    'orderId',
    'person',
    'email',
    'location',
    'date',
    'quantity',
    'amount',
    'products',
    'status',
  ];
  dataEntered = dataEntered.map((item) => {
    return desiredOrder.reduce((acc, key) => {
      acc[key] = item[key];
      return acc;
    }, {});
  });

  const handleStatusChanged = (orderId, status) => {
    dispatch(editOrderStatus(orderId, status));
  };

  const columns = generateColumns(
    dataEntered,
    handleStatusChanged,
    openDeleteModal,
    openShowModal
  );
  return (
    <>
      <div style={{ overflowX: 'auto' }}>
        <ShowOrderModal
          isOpen={isShowOrderModalOpen}
          closeModal={() => setIsShowOrderModalOpen(false)}
          data={dataSelected}
        ></ShowOrderModal>

        <DeleteOrderModal
          isOpen={isDeleteModalOpen}
          closeModal={() => setDeleteModalOpen(false)}
          data={dataSelected}
        ></DeleteOrderModal>

        <DataTable
          customStyles={customStyles}
          columns={columns}
          data={dataEntered}
          fixedHeader
          highlightOnHover
          pointerOnHover
        />
      </div>
    </>
  );
};

export default OrdersDataTable;
