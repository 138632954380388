import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../../Shared/customStyle';
import { useDispatch, useSelector } from 'react-redux';
import {
  aAIHomeSection,
  dIHomeSection,
  rIHomeSection,
} from '../../../Redux/WomensPage/WomensPageActions';
import { toast } from 'react-toastify';
import Loader from '../../../Components/Loader/Loader';
const HomeSectionModal = ({ isOpenModal, closeModal, data }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const dispatch = useDispatch();

  const { loading, error } = useSelector((store) => store.womenPageReducer);

  useEffect(() => {
    if (!loading && !error) {
      _closeModal('addAnotherImage');
      _closeModal('replaceImage');
      _closeModal('deleteImage');
    }
  }, [loading, error]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!selectedImage) {
      toast.error('No Image Selected');
      return;
    }
    dispatch(aAIHomeSection(selectedImage));
  };

  const onChangeImage = (event) => {
    event.preventDefault();
    if (!selectedImage) {
      toast.error('No Image Selected');
      return;
    }
    dispatch(rIHomeSection(data?.id, selectedImage));
  };

  const onDelete = (event) => {
    event.preventDefault();
    dispatch(dIHomeSection(data?.id));
  };

  const _closeModal = (type) => {
    setImagePreview(null);
    setSelectedImage(null);
    closeModal(type);
  };

  return (
    <div>
      <Modal
        style={customStyles}
        isOpen={isOpenModal.addAnotherImage}
        onRequestClose={() => _closeModal('addAnotherImage')}
      >
        {!loading && (
          <div className="flex flex-col gap-7 items-center text-white p-5">
            <h1 className="text-4xl text-center mb-4">Upload Image</h1>

            <form onSubmit={onSubmit} className="flex flex-col gap-4">
              <input
                type="file"
                onChange={handleImageUpload}
                className="border border-gray-400 rounded-lg p-2 bg-gray-800 text-white"
              />
              <button
                type="submit"
                className="p-2 px-5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
              >
                Submit
              </button>
            </form>

            {selectedImage && (
              <div className="mt-4 text-center">
                <h2 className="text-2xl mb-2">Image Preview:</h2>
                <img
                  src={imagePreview}
                  alt="Preview"
                  width={150}
                  className="rounded shadow-lg"
                />
              </div>
            )}
          </div>
        )}

        {loading && <Loader />}
      </Modal>

      <Modal
        isOpen={isOpenModal.replaceImage}
        onRequestClose={() => closeModal('replaceImage')}
        style={customStyles}
      >
        {!loading && (
          <div className="text-white p-5">
            <h1 className="text-4xl text-center mb-4">Edit Home Images</h1>
            <hr className="border-gray-500 mb-4" />

            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex-1">
                <h2 className="text-xl mb-2">Currently Displayed Image</h2>
                <div className="flex justify-center">
                  <img
                    src={process.env.REACT_APP_IMAGE_BASE_URL + data.content}
                    alt="Current"
                    className="rounded shadow-lg"
                    width={200}
                  />
                </div>
              </div>
              <div className="flex-1">
                <h2 className="text-xl mb-2">Change Image</h2>
                <form onSubmit={onChangeImage} className="flex flex-col gap-4">
                  <input
                    type="file"
                    onChange={handleImageUpload}
                    className="border border-gray-400 rounded-lg p-2 bg-gray-800 text-white"
                  />
                  {imagePreview && (
                    <div className="flex justify-center">
                      <img
                        src={imagePreview}
                        alt="Preview"
                        width={150}
                        className="rounded shadow-lg"
                      />
                    </div>
                  )}
                  <button
                    type="submit"
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}

        {loading && (
          <div className=" w-full h-full flex items-center justify-center">
            <Loader />
          </div>
        )}
      </Modal>

      <Modal
        isOpen={isOpenModal.deleteImage}
        onRequestClose={() => closeModal('deleteImage')}
        style={customStyles}
      >
        {!loading && (
          <div className="flex flex-col items-center gap-7 text-white p-5">
            <h1 className="text-4xl text-center mb-4">Delete the Image</h1>
            <img
              src={process.env.REACT_APP_IMAGE_BASE_URL + data.content}
              alt="Delete Preview"
              width={300}
              className="rounded shadow-lg"
            />
            <button
              className="bg-red-500 text-white px-5 p-2 border rounded-lg w-1/2 hover:bg-red-600 transition-colors"
              onClick={(event) => onDelete(event)}
            >
              Yes, Delete
            </button>
          </div>
        )}

        {!loading && <Loader />}
      </Modal>
    </div>
  );
};

export default HomeSectionModal;
