import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOrder } from '../../../Redux/Orders/OrdersActions';
import { customStyles } from '../../../Shared/customStyle';
import Loader from '../../../Components/Loader/Loader';
const DeleteOrderModal = ({ isOpen, closeModal, data }) => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((store) => store.ordersReducer);
  console.log(data);
  useEffect(() => {
    if (!error && !loading) {
      closeModal();
    }
  }, [loading, error]);
  const handleDelete = () => {
    dispatch(deleteOrder(data.orderId));
  };
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Delete Order Modal"
        style={customStyles}
      >
        {!loading && (
          <div className="flex flex-col gap-y-5 p-10">
            <h1 className="text-3xl">
              Are you sure you want to delete this order?
            </h1>
            <div className="flex flex-row gap-x-5">
              <button
                className="bg-red-500 text-white p-2 rounded-lg"
                onClick={() => closeModal()}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white p-2 rounded-lg"
                onClick={() => handleDelete()}
              >
                Confirm
              </button>
            </div>
          </div>
        )}

        {loading && <Loader />}
      </Modal>
    </div>
  );
};

export default DeleteOrderModal;
