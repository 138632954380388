import axios from 'axios';
import { ProductActions } from './ProductReducer';
import { toast } from 'react-toastify';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getProductsData = () => async (dispatch) => {
  try {
    dispatch(ProductActions.getProductsRequest());
    const _response = await axios.get(`${API_BASE_URL}/products/admin`);
    console.log({ Products: _response });
    dispatch(ProductActions.getProductsSuccess(_response.data.data));
  } catch (error) {
    dispatch(ProductActions.getProductsFailure());
  }
};

export const addProduct = (product) => async (dispatch) => {
  try {
    dispatch(ProductActions.addProductRequest());
    const formData = new FormData();
    formData.append('name', product.productName);
    formData.append('price', product.price);
    formData.append('description', product.description);
    formData.append('status', product.status);
    formData.append('image', product.productPhoto);
    formData.append('subscriptionCategory', product.subscriptionCategory);
    formData.append('category', product.category);
    formData.append('size', product.size);
    const response = await axios.post(
      `${API_BASE_URL}/products/create`,
      formData
    );
    console.log(response);
    dispatch(ProductActions.addProductSuccess(response.data));
    dispatch(getProductsData());

    toast.success('Product added successfully!');
  } catch (error) {
    dispatch(ProductActions.addProductFailure());
    toast.error('Error Occurred');
  }
};

export const updateProduct = (productId, updatedData) => async (dispatch) => {
  try {
    dispatch(ProductActions.updateProductRequest());

    const formData = new FormData();
    formData.append('name', updatedData.productName);
    formData.append('price', updatedData.price);
    formData.append('description', updatedData.description);
    formData.append('status', updatedData.status);
    formData.append('image', updatedData.productPhoto);
    formData.append('subscriptionCategory', updatedData.subscriptionCategory);
    formData.append('category', updatedData.category);
    formData.append('size', updatedData.size);
    const response = await axios.patch(
      `${API_BASE_URL}/products/update/${productId}`,
      formData
    );
    dispatch(getProductsData());
    dispatch(ProductActions.updateProductSuccess(response.data));
    toast.success('Product updated successfully');
  } catch (error) {
    dispatch(ProductActions.updateProductFailure());
    toast.error('Error Occurred');
  }
};

export const deleteProduct = (productId) => async (dispatch) => {
  try {
    dispatch(ProductActions.deleteProductRequest());
    const response = await axios.delete(
      `${API_BASE_URL}/products/delete/${productId}`
    );
    dispatch(getProductsData());
    dispatch(ProductActions.deleteProductSuccess(response.data));
    toast.success('Product deleted successfully');
  } catch (error) {
    dispatch(ProductActions.deleteProductFailure());
    toast.error(
      error?.response?.data?.error || 'Something went wrong. Please try again'
    );
  }
};
